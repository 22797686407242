<template>
  <div class="teaching-container evaluate-container">
    <div
      class="container-content"
      :class="collapse ? '' : 'container-content-without-sider'"
    >
      <div class="content-sider">
        <!-- <div> -->
        <div
          class="sider-head"
          title=""
        >{{ unitName }}</div>
        <div class="sider-content">
          <el-form
            :model="evaluate"
            ref="form"
            label-width="80px"
            class="el_from"
            size="mini"
            :rules="rules"
          >
            <div
              class="evaluate-title"
              v-if="isReview == false"
            >写评语</div>
            <div
              class="evaluate-title"
              v-else
            >评语</div>
            <el-input
              maxlength="100"
              :disabled="isReview"
              type="textarea"
              :rows="5"
              placeholder="请输入评语内容"
              v-model="evaluate.content"
            ></el-input>

            <div class="evaluate-title">评分</div>
            <el-form-item
              label="创新程度"
              prop="degreeOfInnovation"
              required
            >
              <el-rate
                :disabled="isReview"
                disabled-void-color="#c6d1de"
                v-model="evaluate.degreeOfInnovation"
              ></el-rate>
            </el-form-item>
            <el-form-item
              label="构思设计"
              prop="ideaInvolves"
              required
            >
              <el-rate
                :disabled="isReview"
                disabled-void-color="#c6d1de"
                v-model="evaluate.ideaInvolves"
              ></el-rate>
            </el-form-item>
            <el-form-item
              label="用户体验"
              prop="userExperience"
              required
            >
              <el-rate
                :disabled="isReview"
                disabled-void-color="#c6d1de"
                v-model="evaluate.userExperience"
              ></el-rate>
            </el-form-item>
            <el-form-item
              label="艺术审美"
              prop="artisticAesthetics"
              required
            >
              <el-rate
                :disabled="isReview"
                disabled-void-color="#c6d1de"
                v-model="evaluate.artisticAesthetics"
              ></el-rate>
            </el-form-item>
            <el-form-item
              label="程序实现"
              prop="programRealization"
              required
            >
              <el-rate
                :disabled="isReview"
                disabled-void-color="#c6d1de"
                v-model="evaluate.programRealization"
              ></el-rate>
            </el-form-item>
            <div
              style="text-align: center"
              v-if="!isReview"
            >
              <el-button
                type="warning"
                @click="submitEvaluate"
              >提交评价</el-button>
            </div>
          </el-form>
        </div>
        <!-- </div> -->
        <div
          class="collapse-change"
          @click="handleCollapseChage"
        >
          <div :class="collapse ? 'icon-close' : 'icon-open'" />
        </div>
      </div>
      <div class="content-main">
        <iframe
          :src="iframeSrc"
          style="width: 100%; height: 100%; background: #fff"
          id="coding"
        />
      </div>
      <div
        v-viewer="imgViewOptions"
        class="images clearfix"
        style="display: none"
      >
        <img
          :src="imgSrc"
          :data-source="imgSrc"
          class="image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, isStudent } from '@/utils/auth';
import { getCourseGuide, getCourseResource, submitEvalute, getEvalute } from '@/api/course';
import TeachingGuideCard from '@/components/teaching/TeachingGuideCard.vue';
import TeachingDataCard from '@/components/teaching/TeachingDataCard.vue';
import PracticeQuestionList from '@/components/teaching/PracticeQuestionList.vue';
import Empty from '@/components/modules/Empty.vue';
import Vue from 'vue';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { Message } from 'element-ui';
export default {
  name: 'evaluate',
  data() {
    return {
      workId: this.$route.query.workId,
      isReview: false,
      evaluate: {
        artisticAesthetics: null,
        content: '',
        degreeOfInnovation: null,
        ideaInvolves: null,
        programRealization: null,
        userExperience: null,
      },
      evaluateUser: {
        classPlanId: '',
        teachingWorkId: '',
        userId: ""
      },
      rules: {
        content: [
          // { required: true, message: '请输入评语内容', trigger: 'change' }
          { max: 100, message: '长度在100个字以内', trigger: 'blur' }
        ],
        artisticAesthetics: [
          { required: true, message: '请对艺术审美进行评分', trigger: 'blur' }
        ],
        degreeOfInnovation: [
          { required: true, message: '请对创新程度进行评分', trigger: 'blur' }
        ],
        ideaInvolves: [
          { required: true, message: '请对构思设计进行评分', trigger: 'blur' }
        ],
        programRealization: [
          { required: true, message: '请对程序实现进行评分', trigger: 'change' }
        ],
        userExperience: [
          { required: true, message: '请对用户体验进行评分', trigger: 'change' }
        ]
      },
      activeTab: 'guide',
      iframeSrc: '',
      unitId: '',
      unitName: '',
      collapse: true,
      totalStep: 0,
      stepIndex: 1,
      guideList: [],
      imgSrc: 'http://localhost:8080/img/match-bg.81a37cf9.png',
      imgViewOptions: {
        toolbar: true,
        url: 'data-source',
      },
      resourceList: [],
      hasGetData: { data: false, practice: false } //是否请求数据
    };
  },
  components: {
    TeachingGuideCard,
    TeachingDataCard,
    PracticeQuestionList,
    Empty,
  },
  created: function () {
    const token = Vue.ls.get(ACCESS_TOKEN);
    if (!token) {
      location.href = `/login?redirect_uri=${encodeURIComponent(location.href)}`;
      // window.location.href = window.location.origin+"/passport/dologin?url="+encodeURI(location.href)
      return;
    }
    if (this.userInfo.role && (
      this.userInfo.role.includes(this.$role.SCHOOLADMIN)
      || this.userInfo.role.includes(this.$role.TEACHER)
      || this.userInfo.role.includes(this.$role.EXAMADMIN))) {
      this.isReview = false
    }
    else {
      this.isReview = true
    }
    this.initEvaluate()
    const lab = this.$route.query.lab;
    const id = this.$route.query.unitId;
    const unitName = this.$route.query.unitName;
    //const workId = this.$route.query.workId;
    if (this.$route.query.isReview && typeof this.$route.query.isReview != 'undefined') this.isReview = true;

    let route = ``;
    if (lab === 'scratch3') {
      //   route = `/${lab}?unitId=${id}`;
      route = `/scratch3/?examAnswerId=1&workId=${this.workId}`;
    } else {
      //   route = `/coding?lab=${lab}?unitId=${id}`;
      route = `/coding/?examAnswerId=1&workId=${this.workId}`;
    }
    this.iframeSrc =
      window.location.host.indexOf('localhost') === -1
        ? `${window.location.protocol}//${window.location.host}${route}`
        : `https://www.eqibian.com${route}`;
    this.unitId = id;
    this.unitName = unitName;
    const params = { courseUnitId: id };
    // getCourseGuide(params)
    //   .then((res) => {
    //     //获取失败
    //     if(!res.success){
    //        this.$message.error(res.message || "获取课程数据失败");
    //        return;
    //     }
    //       const { result = [] } = res;
    //       this.guideList = result;
    //       this.totalStep = (result && result.length) || 0;
    //   })
    //   .catch((err) => {});
  },
  updated: function () {
    const imgEles = document.querySelectorAll('.teaching-guide-card .text img');
    for (let index = 0; index < imgEles.length; index++) {
      const element = imgEles[index];
      element.style.cursor = 'pointer';
      element.style.width = 'auto';
      element.style.height = 'auto';
      element.style.maxWidth = '100%';
      element.style.maxHeight = '100%';
      element.title = '点击放大查看';
      element.addEventListener('click', (e) => {
        this.imgSrc = e.target.src;
        this.show();
      });
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    userInfo: function () {
      return getUserInfo();
    },
  },
  methods: {
    initEvaluate() {
      if (this.workId) {
        getEvalute(this.$route.query.workId).then(res => {
          if (res.success && res.result.evaluate) this.evaluate = res.result.evaluate
          else this.evaluate = {
            artisticAesthetics: null,
            content: '',
            degreeOfInnovation: null,
            ideaInvolves: null,
            programRealization: null,
            userExperience: null,
          }
        })
      }
    },
    submitEvaluate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.evaluateUser = {
            classPlanId: this.$route.query.classPlanId,
            teachingWorkId: this.$route.query.workId,
            userId: this.userInfo.id
          }
          submitEvalute({ evaluate: this.evaluate, evaluateUser: this.evaluateUser }).then((res) => {
            if (res.success) this.$message.success('提交成功！')
            else this.$message.error('提交失败！')
          });
        }
      })
    },
    addClassWithImg(htmlContent) {
      let r = RegExp(/<[img]+\s+(.*?)(?<id>\w*?)[\s'"](.*?)>/g);
      let matchres = htmlContent.match(r); //返回数组['<img ... />','<img ... />']
      if (matchres) {
        matchres.forEach((item, index) => {
          let len = item.length;
          let _str =
            item.slice(0, len - 2) +
            ' style="width:60%;height:200px;text-align:center;margin:0 20%;"/>'; //追加之后的img
          htmlContent = htmlContent.replace(item, _str);
        });
      }
      const resultHtml = parseHtml(htmlContent);
      return resultHtml;
    },
    show() {
      const viewer = this.$el.querySelector('.images').$viewer;
      viewer.show();
    },
    onImgClick(e) { },
    handleNextStepClick() {
      this.handleStepClick(this.stepIndex + 1);
    },
    handleStepClick(step) {
      this.activeTab = 'guide';
      this.stepIndex = step;
      const scrollEle = document.getElementsByClassName('el-tabs__content')[0];
      const guideList = document.getElementById('pane-guide').children;
      scrollEle.scrollTop = 0; //guideList[this.stepIndex - 1].offsetTop;
    },
    handleTabClick(tab, event) {
      if (tab.name === 'data' && !this.hasGetData['data']) {
        getCourseResource({ courseUnitId: this.unitId }).then((res) => {
          //获取失败
          if (!res.success) {
            this.$message.error(res.message || "获取课程数据失败");
            return;
          }
          const result = res.result || [];
          this.resourceList = result;
          this.hasGetData['data'] = true;
        });
      } else if (tab.name === 'practice' && !this.hasGetData['practice']) {
        this.hasGetData['practice'] = true;
      }
    },
    handleCollapseChage() {
      this.collapse = !this.collapse;
    },
    handleSubmitClick() { },
  },
};
</script>
<style scoped>
.sider-content /deep/.el-tabs__nav {
  width: 100% !important;
}
.sider-content /deep/.el-tabs__item {
  padding: 0 12px;
  font-weight: bold;
  width: 50% !important;
  text-align: center;
}
.sider-content /deep/ .el-tabs__item:hover {
  color: #409eff;
}
.sider-content /deep/ .el-tabs__item.is-active {
  color: #409eff;
}
.sider-content /deep/ .el-tabs__active-bar {
  background-color: #409eff;
  height: 4px;
}
.sider-content /deep/ .el-tabs {
  height: 100%;
}
/* .sider-content /deep/ .el-tabs{
        overflow: auto;
        height: calc(100% - 57px);
    } */
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/teaching.scss';
</style>
<style scoped>
.evaluate-title {
  margin: 25px 10px 5px;
}
.evaluate-container .container-content .content-sider {
  top: 0px;
  margin: 0px;
  border-radius: 0px;
}
.evaluate-container .sider-head {
  margin-top: 20px;
}
.evaluate-container .el-rate {
  position: relative;
  top: 4px;
}
</style>
